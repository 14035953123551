<template>
  <div class="progress">
    <div
      class="progress-bar"
      :class="getProgress.color"
      role="progressbar"
      :style="`width: ${getProgress.percentValue}%`"
      :aria-valuenow="getProgress.percentValue"
      aria-valuemin="0"
      aria-valuemax="100">
      <small class="label-center">
        {{assetValue.currentValue}} / {{assetValue.stockValue}}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    assetValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    getProgress () {
      const {percentValue, currentValue, finalValue} = this.assetValue

      let color
      if (currentValue <= finalValue) color = ['bg-danger']
      else color = ['bg-primary']

      return {percentValue, color}
    },
  }
}
</script>

<style lang="css">
.progress {
  position: relative;
}
.label-center {
  position: absolute;
  text-align: center;
  overflow: hidden;
  width: 100%;
}
</style>
