<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="stockAssets"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              {{item.code}}
            </router-link>
            <div class="text-primary">
              {{item.stock.inventory.code}} ({{item.stock.inventory.name}})
            </div>
          </td>
          <td v-if="hidden.stock">
            {{item.stockId}}
          </td>
          <td v-if="hidden.amountRate">
            <span>
              ทางบัญชี: {{item.amountRate}}
              <div class="text-warning">
                ทางภาษี: {{item.refRate}}
              </div>
            </span>
          </td>
          <td v-if="hidden.amountFinal">
            {{item.amountFinal}}
          </td>
          <td v-if="hidden.cron">
            {{item.cron}}
          </td>
        </tr>
        <tr v-if="item.assetValue">
          <td
            :colspan="options.headers.length"
            class="pt-0"
             style="border-top: none !important;">
            <ProgressBar :assetValue="item.assetValue"></ProgressBar>
          </td>
        </tr>
      </template>

      <router-link
        slot="action"
        v-if="$auth.hasRole(`stockAsset:${stockAssetType}:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>
    </sgv-table>
  </div>
</template>

<script>
import { LIST_STOCK_ASSET } from './graph'
import retainMixin from '@/mixins/retain-mixin'
import ProgressBar from './ProgressBar.vue'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    stockAssetType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `StockAsset${this.$form.capitalize(this.stockAssetType)}Detail`,
      rKey: `StockAsset${this.$form.capitalize(this.stockAssetType)}List`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'Stock ID', value: 'stock'},
        {text: 'อัตราค่าเสื่อม', value: 'amountRate'},
        {text: 'มูลค่าสุดท้าย', value: 'amountFinal'},
        {text: 'เวลา (cron)', value: 'cron'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: [
          'code', 'stockId',
          'amountRate', 'amountFinal', 'cron'
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      stockAssets: [],
    }
  },
  apollo: {
    stockAssets: {
      query () {
        return LIST_STOCK_ASSET(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          stockAssetType: this.stockAssetType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params
      }
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {stockAssetId: id}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ProgressBar
  }
}
</script>

<style lang="css" scoped>
</style>
